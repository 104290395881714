import React, { useEffect, useState } from "react";
import axios from "axios";

// components

import CardTable from "components/Cards/CardTable.js";
import Search from "components/Search/Search.jsx";
import Modal from "components/Modals/Modal.jsx";
import { toast, ToastContainer } from "react-toastify";

export default function Tables() {
  //
  const [showModal, setShowModal] = useState(false);
  const [viewBook, setViewBook] = useState();
  const [issued_book, setIssued_book] = useState([]);
  const [query, setQuery] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [links, setLinks] = useState([]);
  const [reload, setReload] = useState(false);

  const isLib = localStorage.getItem("isLib");

  const handleView = (id) => {
    axios
      .get("book-issue/edit/" + id)
      .then((response) => {
        console.log(response.data);
        setViewBook(response.data);
        reload ? setReload(false) : setReload(true);
      })
      .catch((error) => {
        console.log(error.message);
      });
    setShowModal(true);
  };
  const handleReceive = (id) => {
    axios
      .post("book-issue/update/" + id)
      .then((response) => {
        console.log(response);
        axios
          .post("book-issue/delete/" + id)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    toast.success("Received & cleared");
    setShowModal(false);
    setQuery("");
    reload ? setReload(false) : setReload(true);
  };

  useEffect(() => {
    axios.get("/book_issue/" + query).then((response) => {
      setIssued_book(response.data.data);
      setPagination(response.data);
      setLinks(response.data.links);
    });
  }, [query, reload]);
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            tableTitle="All issued books"
            search={
              <Search
                input={
                  <input
                    type="text"
                    class="h-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60  pl-10   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Students with books"
                  />
                }
              />
            }
            col_label1="Adm"
            col_label2="Student name"
            col_label3="book title"
            col_label4="number"
            col_label5="issued on"
            col_label6="return date"
            col_label7="Receive"
            tableRows={issued_book.map((book, index) => (
              <tr key={index}>
                <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left ">
                  {book.student.adm}
                </th>
                <th className="border-t-0 px-2 text-left uppercase border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.student.name}
                </th>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.book.name}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.book.number}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.issue_date}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.return_date}
                </td>
                <td>
                  {isLib ? (
                    <button
                      className="bg-lightBlue-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleView(book.id)}
                    >
                      View
                    </button>
                  ) : (
                    <button className="text-sm" disabled>
                      Disabled
                    </button>
                  )}
                </td>
              </tr>
            ))}
          />
        </div>
        <div className="w-full p-4 flex items-center text-center">
          <div className="bg-lightBlue-900 p-2 font-light text-white w-full mb-12 px-4 rounded-lg">
            <span className="px-4 mr-20">
              {" "}
              Showing page : {pagination.current_page} of {pagination.last_page}
            </span>
            {links.map((link) => {
              var label = link.label;
              if (label === "&laquo; Previous") {
                label = "Prev";
              }
              {
                /* line one is a bug debeg ' */
              }
              if (!link.url) {
                link.url = "";
              }
              var url = link.url;
              var firstLabel = label.split(" ");
              var lastUrl = url.substring("54");
              return (
                <button
                  className="active focus  px-2 rounded-full bg-orange-500 active:border-indigo-500"
                  onClick={() => setQuery(lastUrl)}
                >
                  {firstLabel[0]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        modalStateProp={showModal}
        modalTitle="Receive this Book"
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        children={
          viewBook ? (
            <div className="p-4">
              <p>Student Name : {viewBook.book.student.name}</p>
              <p>ADM NO : {viewBook.book.student.adm}</p>
              <p>Guardian Phone : {viewBook.book.student.phone}</p>
              <p>Book Price : {viewBook.book.cost}</p>
              <p>Issued On : {viewBook.book.issue_date}</p>
              <p>Returning Date : {viewBook.book.return_date}</p>

              <p>Fine: Ksh. {viewBook.fine}</p>
              <div className="w-full text-center p-4 items-center">
                <button
                  className="bg-lightBlue-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-2 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleReceive(viewBook.book.id, "clear")}
                >
                  Receive & clear
                </button>
              </div>
            </div>
          ) : null
        }
      />
      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
