import { useEffect} from "react";
import { toast,ToastContainer } from "react-toastify";


const Notification = () => {
    useEffect(()=>{ toast.info('No notifications Available')},[]       
    )
    return (        <ToastContainer position="bottom-right"
    autoClose={1500}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"/> );
}
 
export default Notification;