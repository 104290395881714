import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Books from "views/admin/books";
import Category from "views/admin/category";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Authors from "views/admin/authors";
import Students from "views/admin/students";
import Alumni from "views/admin/alumni";
import Publishers from "views/admin/publishers";
import Dailyreport from "views/admin/dailyreport";
import Monthlyreport from "views/admin/monthlyreport";
import Unreturned from "views/admin/unreturned";
import Profile from "views/admin/profile";
import Issued_books from "views/admin/issued_books";
import Notifications from "views/admin/notifications";
import Staff from "views/admin/staff";
import Staff_borrowed from "views/admin/staff_issued";
import Users from "views/admin/users";
import Name_count from "views/admin/book_title_count";
import Cat_count from "views/admin/book_cat_count";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/authors" exact component={Authors} />
            <Route path="/admin/category" exact component={Category} />
            <Route path="/admin/unreturned" exact component={Unreturned} />
            <Route path="/admin/dailyreport" exact component={Dailyreport} />
            <Route
              path="/admin/monthlyreport"
              exact
              component={Monthlyreport}
            />
            <Route path="/admin/students" exact component={Students} />
            <Route path="/admin/alumni" exact component={Alumni} />
            <Route path="/admin/publishers" exact component={Publishers} />
            <Route path="/admin/issued_books" exact component={Issued_books} />
            <Route path="/admin/maps" exact component={Maps} />
            <Route path="/admin/profile" exact component={Profile} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/books" exact component={Books} />
            <Route
              path="/admin/notifications"
              exact
              component={Notifications}
            />
            <Route path="/admin/staff" exact component={Staff} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/name_count" exact component={Name_count} />
            <Route path="/admin/cat_count" exact component={Cat_count} />
            <Route
              path="/admin/staff_borrowed"
              exact
              component={Staff_borrowed}
            />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
