import React, { useEffect, useState } from "react";
import axios from "axios";

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";
import Search from "components/Search/Search.jsx";
import InputModal from "components/Modals/InputModal.jsx";
import { toast, ToastContainer } from "react-toastify";
import Modal from "components/Modals/Modal.jsx";
export default function Staff() {
  const options = [
    { value: "1", label: "Form 1" },
    { value: "2", label: "Form 2" },
    { value: "3", label: "Form 3" },
    { value: "4", label: "Form 4" },
  ];

  //
  const [showModal, setShowModal] = React.useState(false);
  const [query, setQuery] = useState([]);
  const [staff, setstaff] = useState([]);
  const [view, setView] = useState([]);
  //for casting viewd staff data
  const [view_employeeno, setViewemployeeno] = useState([]);
  const [view_name, setViewName] = useState();
  //end offor casting viewd staff data
  const [pagination, setPagination] = useState([]);
  const [links, setLinks] = useState([]);
  const [createInputs, setCreateInputs] = useState({});
  const [showModal3, setShowModal3] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const handleChange = (event) => {
    const name = event.target.name || event.target.label;
    const value = event.target.value;

    setCreateInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSelect = (selectedOption) => {
    console.log("selected option");
    var values = Object.values(selectedOption);
    var value = values[0];
    const selectObj = { class: value };
    setSelectedOption(selectObj);
  };

  const handleSubmit = () => {
    setCreateInputs(Object.assign(createInputs, selectedOption));
    console.log(createInputs);
    if (!createInputs.employeeno) {
      toast.error("Please fill all the fields");
      return 0;
    }
    axios
      .post("staff/create", createInputs)
      .then((response) => {
        console.log(response);
        setShowModal(false);
        toast.success("success");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleView = (id) => {
    console.log(id);
    axios.get("book_issue_staff/view/" + id).then((response) => {
      setView(response.data);
      try {
        setViewName(response.data[0].staff.name);
        setViewemployeeno(response.data[0].staff.employeeno);
        console.log(view_name);
      } catch {}
      setShowModal3(true);
    });
  };

  useEffect(
    () => {
      console.log("query param:");
      console.log(query);
      axios.get("/staff/" + query).then((response) => {
        console.log(response.data.data);
        setstaff(response.data.data);
        setPagination(response.data);
        setLinks(response.data.links);
      });
    },
    // eslint-disable-next-line
    [query]
  );
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            tableTitle="All staff"
            action={
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                add Staff
              </button>
            }
            search={
              <Search
                input={
                  <input
                    type="text"
                    class="h-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60  pl-10   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search staff"
                  />
                }
              />
            }
            col_label1="Staff number"
            col_label2="title"
            col_label3="Name"
            col_label4="phone"
            col_label="action"
            tableRows={staff.map((staff, index) => (
              <tr key={index}>
                <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left ">
                  {staff.employeeno}
                </th>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {staff.title}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {staff.name}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {staff.phone}
                </td>

                <td>
                  {" "}
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown
                      action1={
                        <button
                          className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => handleView(staff.id)}
                        >
                          View
                        </button>
                      }
                      action2={
                        <button
                          className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(true)}
                        >
                          Delete
                        </button>
                      }
                    />
                  </td>
                </td>
              </tr>
            ))}
          />
        </div>

        <div className="w-full p-4 flex items-center text-center">
          <div className="bg-lightBlue-900 p-2 font-light text-white w-full mb-12 px-4 rounded-lg">
            <span className="px-4 mr-20">
              {" "}
              Showing page{pagination.current_page} of {pagination.last_page}
            </span>
            {links.map((link) => {
              var label = link.label;
              if (label === "&laquo; Previous") {
                label = "Prev";
              }
              {
                /* line one is a bug debeg ' */
              }
              if (!link.url) {
                link.url = "";
              }
              var url = link.url;
              var firstLabel = label.split(" ");
              var lastUrl = url.slice("-7");
              return (
                <button
                  className="active focus  px-2 rounded-full bg-orange-500 active:border-indigo-500"
                  onClick={() => setQuery(lastUrl)}
                >
                  {firstLabel[0]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {/*modals*/}

      <InputModal
        modalStateProp={showModal}
        modalTitle="Add Staff"
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="employeeno"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Staff Number
            </label>
            <input
              id="employeeno"
              type="bookname"
              name="employeeno"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.employeeno || ""}
              onChange={handleChange}
            />
            <label
              for="name"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Full Name
            </label>
            <input
              id="name"
              type="name"
              name="name"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.name || ""}
              onChange={handleChange}
            />
            <label
              for="title"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Title
            </label>
            <input
              id="title"
              type="publisher"
              name="title"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.title || ""}
              onChange={handleChange}
            />
            <label
              for="phone"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              {" "}
              phone
            </label>
            <input
              id="phone"
              type="publisher"
              name="phone"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.phone || ""}
              onChange={handleChange}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </button>
          </>
        }
      />
      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        modalStateProp={showModal3}
        modalTitle={view_name}
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal3(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        children={
          <>
            {view_name ? (
              <>
                <p>Id Number: {view_employeeno}</p>
                <span className="font-bold underline"></span>Book(s) Info :
                {view.map((view, index) => (
                  <>
                    <hr />
                    <p key={index}>
                      Name:{view.book.name} issued on{" "}
                      <span className="text-red-500"> {view.issue_date} </span>{" "}
                      to return on{" "}
                      <span className="text-red-500"> {view.return_date} </span>
                    </p>
                  </>
                ))}
              </>
            ) : (
              <p>staff Is clear</p>
            )}
          </>
        }
      />
    </>
  );
}
