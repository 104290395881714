import React, {useEffect ,useState} from "react"
import axios  from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// components
import CardTable from "components/Cards/CardTable.js";



export default function Tables() {
  //
  const [cat , setCat] = useState([])
  const [reload , setReload] = useState(false)  
    

  useEffect(() => {   
        axios.get("/count_cat").then(response=>{
          setCat(response.data)  
            });  
       },
       // eslint-disable-next-line
       [reload])
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
          tableTitle="book per subject count"   
          col_label1="title"
          col_label2="count"
          tableRows={<>
            <tr >
              <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Mathematics</th>  
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.mathematics}</td>
            </tr>  
                  <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >English</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.english}</td>
                </tr>  
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Kiswahli</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.kiswahili}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >CRE</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.cre}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Geography</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.geography}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >History</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.history}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Agriculture</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.agriculture}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Chemistry</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.chemistry}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Biology</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.biology}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Business</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.business}</td>
                </tr> 
                <tr >
                  <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >Geography</th>  
                  <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{cat.geography}</td>
                </tr> 

                </>            
          }
          />
    
        </div>

      </div>
         {/*modals*/}     
    
         <ToastContainer position="bottom-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"/> 
    </>
  );
}
