import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";
import Search from "components/Search/Search.jsx";
import InputModal from "components/Modals/InputModal.jsx";
import { toast, ToastContainer } from "react-toastify";
import Modal from "components/Modals/Modal.jsx";
export default function Students() {
  const options = [
    { value: "1", label: "Form 1" },
    { value: "2", label: "Form 2" },
    { value: "3", label: "Form 3" },
    { value: "4", label: "Form 4" },
  ];

  const options2 = [
    { value: "red", label: "red" },
    { value: "blue", label: "blue" },
    { value: "violet", label: "violet" },
    { value: "green", label: "green" },
    { value: "white", label: "white" },
    { value: "purple", label: "purple" },
    { value: "yellow", label: "yellow" },
    { value: "east", label: "east" },
    { value: "west", label: "west" },
    { value: "north", label: "north" },
    { value: "south", label: "south" },
  ];

  //
  const [showModal, setShowModal] = React.useState(false);
  const [query, setQuery] = useState([]);
  const [students, setStudents] = useState([]);
  const [view, setView] = useState([]);
  //for casting viewd student data
  const [view_adm, setViewAdm] = useState([]);
  const [view_name, setViewName] = useState();
  //end offor casting viewd student data
  const [pagination, setPagination] = useState([]);
  const [links, setLinks] = useState([]);
  const [createInputs, setCreateInputs] = useState({});
  const [showModal3, setShowModal3] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOption2, setSelectedOption2] = useState();
  const [reload, setReload] = useState(false);

  const isAdmin = localStorage.getItem("isAdmin");
  const isLib = localStorage.getItem("isLib");

  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.document.title = "Kisasi Secondary School";
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete item",
      message: "Action is irreversible",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios
              .post("/student/delete/" + id)
              .then((response) => {
                console.log(response);
                toast.success("Done");
              })
              .catch((err) => {
                console.log(err);
                toast.error("seems this data is in use");
              });
            reload ? setReload(false) : setReload(true);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleChange = (event) => {
    const name = event.target.name || event.target.label;
    const value = event.target.value;

    setCreateInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSelect = (selectedOption) => {
    console.log("selected option");
    var values = Object.values(selectedOption);
    var value = values[0];
    const selectObj = { class: value };
    setSelectedOption(selectObj);
  };
  const handleChangeSelect2 = (selectedOption) => {
    console.log("selected option");
    var values = Object.values(selectedOption);
    var value = values[0];
    const selectObj = { stream: value };
    setSelectedOption2(selectObj);
  };

  const handleSubmit = () => {
    setCreateInputs(Object.assign(createInputs, selectedOption));
    setCreateInputs(Object.assign(createInputs, selectedOption2));
    console.log(createInputs);
    if (
      !createInputs.adm ||
      !createInputs.name ||
      !createInputs.class ||
      !createInputs.stream ||
      !createInputs.phone
    ) {
      toast.error("Please fill all the fields");
      return 0;
    }
    axios
      .post("student/create", createInputs)
      .then((response) => {
        console.log(response);
        setShowModal(false);
        toast.success("success");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const handleView = (id) => {
    console.log(id);
    axios.get("book_issue/view/" + id).then((response) => {
      setView(response.data);
      try {
        setViewName(response.data[0].student.name);
        setViewAdm(response.data[0].student.adm);
        console.log(view_name);
      } catch {}
      setShowModal3(true);
    });
  };

  useEffect(
    () => {
      console.log("islib:", isLib);
      console.log(query);
      axios.get("/students/" + query).then((response) => {
        setStudents(response.data.data);
        setPagination(response.data);
        setLinks(response.data.links);
      });
    },
    // eslint-disable-next-line
    [query, reload]
  );
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            tableTitle="All students"
            action={
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  if (!isAdmin || !isLib) {
                    toast.error("Action denied");
                    return 0;
                  }
                  setShowModal(true);
                }}
              >
                Add Student
              </button>
            }
            search={
              <Search
                input={
                  <input
                    type="text"
                    class="h-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60  pl-10   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search Students"
                  />
                }
              />
            }
            col_label1="Adm no"
            col_label2="name"
            col_label3="class"
            col_label4="stream"
            col_label5="Phone"
            col_label6="action"
            tableRows={students.map((student, index) => (
              <tr key={index}>
                <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left ">
                  {student.adm}
                </th>
                <td className="border-t-0 px-2 uppercase align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {student.name}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {student.class}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {student.stream}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {student.phone}
                </td>
                <td>
                  {isLib ? (
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                      <TableDropdown
                        action1={
                          <button
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => handleView(student.id)}
                          >
                            View
                          </button>
                        }
                        action2={
                          <button
                            className="bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => handleDelete(student.id)}
                          >
                            Delete
                          </button>
                        }
                      />
                    </td>
                  ) : (
                    <p className="text-sm">Disabled</p>
                  )}
                </td>
              </tr>
            ))}
          />
        </div>

        <div className="w-full p-4 flex items-center text-center">
          <div className="bg-lightBlue-900 p-2 font-light text-white w-full mb-12 px-4 rounded-lg">
            <span className="px-4 mr-20">
              {" "}
              Showing page{pagination.current_page} of {pagination.last_page}
            </span>
            {links.map((link) => {
              var label = link.label;
              if (label === "&laquo; Previous") {
                label = "Prev";
              }
              {
                /* line one is a bug debeg '  this pagination is page specific coded custom for this website edit when using it somewhere - Github/MeshackNzuki dev*/
              }
              if (!link.url) {
                link.url = "";
              }
              var url = link.url;
              var firstLabel = label.split(" ");
              var lastUrl = url.substring("52");
              return (
                <button
                  className="active focus  px-2 rounded-full bg-orange-500 active:border-indigo-500"
                  onClick={() => setQuery(lastUrl)}
                >
                  {firstLabel[0]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {/*modals*/}

      <InputModal
        modalStateProp={showModal}
        modalTitle="Add Student"
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="adm"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Student Admission Number
            </label>
            <input
              id="adm"
              type="bookname"
              name="adm"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.adm || ""}
              onChange={handleChange}
            />
            <label
              for="name"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Student Full Name
            </label>
            <input
              id="name"
              type="name"
              name="name"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.name || ""}
              onChange={handleChange}
            />
            <label
              for="gender"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Gender
            </label>
            <input
              id="gender"
              type="gender"
              name="gender"
              placeholder=""
              autocomplete="gender"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.gender || ""}
              onChange={handleChange}
            />
            <label
              for="class"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Class
            </label>
            <Select
              name="class"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              options={options}
              onChange={handleChangeSelect}
            />
            <label
              for="stream"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Stream
            </label>
            <Select
              name="stream"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              options={options2}
              onChange={handleChangeSelect2}
            />
            <label
              for="phone"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              guardian phone
            </label>
            <input
              id="phone"
              type="publisher"
              name="phone"
              placeholder=""
              autocomplete="new-publisher"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.phone || ""}
              onChange={handleChange}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </button>
          </>
        }
      />
      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        modalStateProp={showModal3}
        modalTitle={view_name}
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal3(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        children={
          <>
            {view_name ? (
              <>
                <p>Admission no: {view_adm}</p>
                <span className="font-bold underline"></span>Book(s) Info :
                {view.map((view, index) => (
                  <>
                    <hr />
                    <p key={index}>
                      Name:{view.book.name} issued on{" "}
                      <span className="text-red-500"> {view.issue_date} </span>{" "}
                      to return on{" "}
                      <span className="text-red-500"> {view.return_date} </span>
                    </p>
                  </>
                ))}
                <div className="w-full">
                  <section class="py-1 bg-blueGray-50 hidden" id="printablediv">
                    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4 mx-auto mt-24">
                      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
                        <div class="rounded-t mb-0 px-4 py-3 border-0">
                          <div class="flex flex-wrap items-center">
                            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                              <h2 className="text-red-500 font-semibold text-base mb-4 mt-4">
                                INVOICE - Kisasi Secondary School Library
                              </h2>
                              <hr />
                            </div>
                          </div>
                        </div>

                        <div class="block w-full overflow-x-auto">
                          <table class="items-center bg-transparent w-full border-collapse ">
                            <thead>
                              <tr>
                                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                  STUDENT NAME
                                </th>
                                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                  STUDENT ADMISSION
                                </th>
                                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                  BOOK NAME
                                </th>
                                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                  PRICE
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                                  {view_name}
                                </th>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                  {view_adm}
                                </td>
                                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  -
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  -
                                </td>
                              </tr>
                              {view.map((view, index) => (
                                <>
                                  <tr>
                                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 "></th>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "></td>
                                    <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {" "}
                                      {view.book.name}
                                    </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      {view.book.price}
                                    </td>
                                  </tr>
                                </>
                              ))}
                              <tr>
                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 "></th>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "></td>
                                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"></td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-semibold"></td>
                              </tr>
                              <tr>
                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 "></th>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "></td>
                                <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"></td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <hr></hr>
                                </td>
                              </tr>
                              <tr>
                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 "></th>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "></td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-semibold">
                                  Total
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  Ksh 0.00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={Print}
                  >
                    Print Invoice
                  </button>
                </div>
              </>
            ) : (
              <p>Student Is clear</p>
            )}
          </>
        }
      />
    </>
  );
}
