import React, {useState} from "react"
import PropTypes from "prop-types";

// components

export default function CardTable({ 

tableTitle,
action,
search,
col_label1,
col_label2,
col_label3,
col_label4,
col_label5,
col_label6,
col_label7,
tableRows
}) {

 const [color , setMode] = useState(localStorage.getItem('color'))
 
  const toggleMode =()=>{      // color==='light'? setMode('dark'): setMode('light')
    
   const colorstored = localStorage.getItem('color');

   if(colorstored==='light'){
    setMode('dark') 
    localStorage.setItem('color','dark')
   }
   else{
    setMode('light')
    localStorage.setItem('color','light')
   }
   


  }
     
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-2 py-3 border-0">
      
            <div className="flex justify-between place-items-center relative w-full px-4 max-w-full ">
              <div>
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >            
                {tableTitle}                        

              </h3>        
               </div> 
               <div>
                {action} 
              </div>
               <div>
               {search}
                </div> 
               
                <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                </svg>

                <div class="w-12 relative select-none  transition duration-300 ease-in">
                <input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute  w-6 h-6 rounded-full bg-white border-4  appearance-none cursor-pointer" onClick={toggleMode}/>
                <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
                  </label>             
               </div>
                </div>
            
            </div>
         
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  {col_label1}
                </th>
                <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                     {col_label2}
                </th>
                <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                     {col_label3}
                </th>
                <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                     {col_label4}
                </th>
                <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                     {col_label5}
                </th>
                <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }                 
                > {col_label6}</th>
                        <th
                  className={
                    "px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }                 
                > {col_label7}</th>
              </tr>
            </thead>
            <tbody>
         
            {tableRows} 
                            
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}


CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
    col_labe1: PropTypes.string,
    col_labe2: PropTypes.string,
    col_labe3: PropTypes.string,
    col_labe4: PropTypes.string,
    col_labe5: PropTypes.string,
  
};
