/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 bg-lightBlue-900">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-lightBlue-900 m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold  p-4 px-0"
            to="/"
          >
            KISASI SECONDARY SCHOOL
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none"></ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase  p-4 px-0"
                    to="/"
                  ></Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/books") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/books"
                >
                  <i
                    className={
                      "fa fa-book mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/books") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  ALL BOOKS
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/students") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/students"
                >
                  <i
                    fa-bar-chart
                    className={
                      "fas fa-users mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/students") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  ALL STUDENTS
                </Link>
              </li>{" "}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/alumni") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/alumni"
                >
                  <i
                    fa-bar-chart
                    className={
                      "fas fa-users mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/alumni") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Alumni
                </Link>
              </li>{" "}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/issued_books") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/issued_books"
                >
                  <i
                    className={
                      "fa fa-arrow-right mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/issued_books") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  BORROWED BOOKS
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/authors") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/authors"
                >
                  <i
                    className={
                      "<fa fa fa-pen  mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/authors") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Authors
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/publishers") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/publishers"
                >
                  <i
                    className={
                      "fa fa-stamp mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/publishers") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  PUBLISHER
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/staff") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/staff"
                >
                  <i
                    className={
                      "fas fa-user mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/staff") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  STAFF
                  <div className="p-2">
                    <Link
                      className={
                        "text-xs uppercase   block acti:text-orange-500 " +
                        (window.location.href.indexOf(
                          "/admin/staff_borrowed"
                        ) !== -1
                          ? " text-orange-500  hover:text-orange-500"
                          : "text-blueGray-100 hover:text-orange-500")
                      }
                      to="/admin/staff_borrowed"
                    >
                      <i
                        className={
                          "fas fa-file mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/staff_borrowed"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      staff borrowed books
                    </Link>
                  </div>
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/category") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/category"
                >
                  <i
                    className={
                      "fas fa-list mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/category") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  CATEGORY
                </Link>
                <Link
                  className={
                    "text-xs  py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/reports") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="javaScript:void()"
                >
                  <i
                    className={
                      "fa fa-file mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/reports") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  REPORTS
                  <div className="p-2">
                    <Link
                      className={
                        "text-xs uppercase  block acti:text-orange-500 " +
                        (window.location.href.indexOf("/admin/reports") !== -1
                          ? " text-orange-500  hover:text-orange-500"
                          : "text-blueGray-100 hover:text-orange-500")
                      }
                      to="/admin/dailyreport"
                    >
                      <i
                        className={
                          "fa fa-file mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/dailyreport"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      daily reports
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3  block acti:text-orange-500 " +
                        (window.location.href.indexOf(
                          "/admin/monthlyreport"
                        ) !== -1
                          ? " text-orange-500  hover:text-orange-500"
                          : "text-blueGray-100 hover:text-orange-500")
                      }
                      to="/admin/monthlyreport"
                    >
                      <i
                        className={
                          "fa fa-file mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/admin/monthlyreport"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      monthly reports
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase   block acti:text-orange-500 " +
                        (window.location.href.indexOf("/admin/unreturned") !==
                        -1
                          ? " text-orange-500  hover:text-orange-500"
                          : "text-blueGray-100 hover:text-orange-500")
                      }
                      to="/admin/unreturned"
                    >
                      <i
                        className={
                          "fa fa-file mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/unreturned") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Lost books
                    </Link>
                  </div>
                </Link>

                <Link
                  className={
                    "text-xs uppercase  block acti:text-orange-500 mb-2 " +
                    (window.location.href.indexOf("/admin/cat_count") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/cat_count"
                >
                  <i
                    className={
                      "fa fa-file mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/cat_count") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  book Count per Subject
                </Link>
                <Link
                  className={
                    "text-xs uppercase  block acti:text-orange-500 mb- " +
                    (window.location.href.indexOf("/admin/name_count") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/name_count"
                >
                  <i
                    className={
                      "fa fa-file mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/name_count") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  book Count per title
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3  block acti:text-orange-500 " +
                    (window.location.href.indexOf("/admin/settings") !== -1
                      ? " text-orange-500  hover:text-orange-500"
                      : "text-blueGray-100 hover:text-orange-500")
                  }
                  to="/admin/settings"
                >
                  <i
                    className={
                      "fas fa-cog mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Settings
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col uppercase list-none md:mb-4">
              <li className="items-center">
                <Link
                  className="text-blueGray-100 hover:text-blueGray-100 text-xs uppercase py-3  block acti:text-orange-500"
                  to="/admin/notifications"
                >
                  <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Notifications
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-100 hover:text-blueGray-100 text-xs  py-3  block acti:text-orange-500"
                  to="/admin/profile"
                >
                  <i className="fas fa-user-circle text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Profile
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className="text-blueGray-100 hover:text-blueGray-100 text-xs py-3  block acti:text-orange-500"
                  to="/register"
                >
                  <i className="fas fa-user-circle text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Add New User (Admin)
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className="text-blueGray-100 hover:text-blueGray-100 text-xs py-3  block acti:text-orange-500"
                  to="admin/users"
                >
                  <i className="fas fa-user-circle text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Manage Users (Admin)
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
