
import React from 'react';
import {createPopper} from "@popperjs/core";
import Portal from "./Portal";
import FocusTrap from "focus-trap-react";



class NotificationDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.triggerRef = React.createRef();
        this.NotificationDropdownRef = React.createRef();

        this.state = {
            isOpen: false,
        };

        this.toggleNotificationDropdown = this.toggleNotificationDropdown.bind(this);
    }

    componentDidMount() {
        console.log(this.triggerRef.current);
        console.log(this.NotificationDropdownRef.current);

        this.popper = createPopper(
            this.triggerRef.current,
            this.NotificationDropdownRef.current,
            {
                placement: this.props.placement || 'bottom-start',
                strategy: this.props.strategy || 'fixed',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                    ...this.props.modifiers || [],
                ],
            }
        );
    }

    componentWillUnmount() {
        this.popper.destroy();
    }

    toggleNotificationDropdown() {
        this.setState((state) => {
            return {
                isOpen: !state.isOpen,
            }
        }, () => {
            this.popper.forceUpdate();
        })
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className="trigger"
                    ref={this.triggerRef}
                    onClick={this.toggleNotificationDropdown}
                >
                    <p className='text-2xl pointer-events-auto'>...</p>
                </div>

                <Portal>
                    <div className="NotificationDropdown__container" ref={this.NotificationDropdownRef}>
                        {this.state.isOpen && <React.Fragment>
                            <FocusTrap focusTrapOptions={{
                                onDeactivate: this.toggleNotificationDropdown,
                                clickOutsideDeactivates: true,
                            }}>
                                <div
                                    className="bg-gray-200 p-2 rounded-sm shadow-lg text-gray-700 flex flex-wrap  justify-center"
                                >
                                    <div tabIndex={0} className="border-blue-500">
                                    <a
                                href="#pablo"
                                className={
                                    "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700"
                                }
                                onClick={(e) => e.preventDefault()}
                                >
                                {this.props.action1}
                                </a>
                                <a
                                href="#pablo"
                                className={
                                    "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700"
                                }
                                onClick={(e) => e.preventDefault()}
                                >
                                {this.props.action2}
                                </a>
                                                            </div>
                                </div>
                            </FocusTrap>
                        </React.Fragment>}
                    </div>
                </Portal>
            </React.Fragment>
        )
    }
}

export default NotificationDropdown;