import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function Navbar() {
  const history = useHistory();
  
  const  reload = ()=>{
    window.location.reload()
   }
  const user = localStorage.getItem('name');  
  const logout=()=>{
    axios.post('/logout')
    localStorage.removeItem('token','auth','name','isAdmin','isLib'); 
    history.push("/login")
  }
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm hidden lg:inline-block "
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Dashboard
          </a>
          <a
            className="text-white animate:pulse text-sm hidden lg:inline-block "
            href="#"
            onClick={reload}
          > <i className="fa fa-bolt ml-8"></i>
          </a>     <a
            className="text-red-400 animate:pulse text-sm hidden lg:inline-block "
            href="#"
            onClick={reload}
          > <i className="fa fa-reload ml-2"></i>
          </a>
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
       
            <a
            className="text-white text-sm hidden lg:inline-block "
            href="#pablo"
            onClick={() => logout()}
          >
            Logout
          </a>
         
            </div>
          </form>
          {/* User */}
          <span className="text-orange-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
           </svg>
           </span>
           <span className="text-white font-light">{user}</span>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
