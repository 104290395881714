import React, { useEffect, useState } from "react";
import axios from "axios";
import { differenceInDays, format } from "date-fns";
import DatePicker from "react-datepicker";
// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";
import Search from "components/Search/Search.jsx";

export default function Books() {
  //
  const [query, setQuery] = useState([]);
  const [books, setBooks] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    console.log("query param:");
    console.log(query);
    axios.get("/reports/not-returned/" + query).then((response) => {
      setPagination(response.data);
      setLinks(response.data.links);
      setBooks(response.data.data);
    });
  }, [query]);

  return (
    <div className="container relative ">
      <div className="relative flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            tableTitle="Lost books"
            col_label1="ADM"
            col_label2="STUDENT NAME"
            col_label3="BOOK NAME"
            col_label4="GUARDIAN PHONE"
            col_label5="ISSUE DATE"
            col_label6="RETURN DATE"
            col_label7="OVER DAYS"
            tableRows={books.map((book, index) => (
              <tr key={index}>
                <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left ">
                  {book.student.adm}
                </th>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.student.name}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.book.name}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.student.phone}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.issue_date}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {book.return_date}
                </td>
                <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                  {differenceInDays(new Date(), new Date(book.return_date))}
                </td>
              </tr>
            ))}
          />
        </div>
        <div className="w-full p-4 flex items-center text-center">
          <div className="bg-lightBlue-900 p-2 font-light text-white w-full mb-12 px-4 rounded-lg">
            <span className="px-4 mr-20">
              {" "}
              Showing page{pagination.current_page} of {pagination.last_page}
            </span>
            {links.map((link) => {
              var label = link.label;
              if (label === "&laquo; Previous") {
                label = "Prev";
              }
              {
                /* line one is a bug debeg ' */
              }
              if (!link.url) {
                link.url = "";
              }
              var url = link.url;
              var firstLabel = label.split(" ");
              var lastUrl = url.slice("-7");
              return (
                <button
                  className="active focus  px-2 rounded-full bg-orange-500 active:border-indigo-500"
                  onClick={() => setQuery(lastUrl)}
                >
                  {firstLabel[0]}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {/*modals*/}
    </div>
  );
}
