import {React, useState} from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer} from "react-toastify";
import Sidebar from "components/Sidebar/Sidebar.js";




export default function Register() { 
  const [username, setUsername] = useState(0);
  const [ability, setAbility] = useState('3');
  const [email, setEmail] = useState(0);
  const [password, setPassword] = useState(0);
  const [confirm_password, setConfirmPassword] = useState(0);
   const auth = localStorage.getItem("auth")
   const isAdmin = localStorage.getItem("isAdmin")
   const isLib = localStorage.getItem("isLib")


   console.log('is admin:',isAdmin,)
   console.log('is libralian:',isLib,)
   console.log('is auth:',auth,)
  const register = (event) => {
    event.preventDefault()
    var data=''
    if(ability==='1'){data={
      email:email,
      username:username,
      password:password,
      isAdmin:1,
      isLib:1
    }}
    if(ability==='2'){ data={
      email:email,
      username:username,
      password:password,
      isAdmin:0,
      isLib:1
    }}
    if(ability==='3'){ data={
      email:email,
      username:username,
      password:password,
      isAdmin:0,
      isLib:0
    }}
 
   
    console.log(ability)
    console.log('data:',data)
   if (confirm_password===password) {

   axios.post('/register', data)
        .then(response => {               
          toast.success('User has been Added')                   
        })    
      } else {
        toast.error("Passwords Dont match")
      }
 

  }
  
  return (
    <>
    <Sidebar/>
    {isAdmin?(<>
      <div className="md:ml-64container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-1/2 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                 <div className="flex justify-center items-center">                
                </div>              
              </div>
              <div className="flex-auto px-2 lg:px-10 py-10 pt-0">
                  <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      onChange={event => setEmail(event.target.value)}
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      User name
                    </label>
                    <input
                      onChange={event => setUsername(event.target.value)}
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="user name"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                     onChange={event => setPassword(event.target.value)}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                    Confirm  Password
                    </label>
                    <input
                     onChange={event => setConfirmPassword(event.target.value)}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div>
                    <p className="mb-2">Choose user type</p>         

                    <select className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" onChange={(e)=>setAbility(e.target.value)}>                   
                      <option value="3">Read Only </option>
                      <option value="1">Administrator - Has Overall previlleges</option>
                      <option value="2">Libralian - Can perfom intermediate administrative tasks</option>
                    </select>                                 
               
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="bg-lightBlue-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={register}
                    >
                      Create User
                    </button>
                  </div>
                </form>
              </div>
            </div>
           </div>
        </div>
      </div>
      <ToastContainer position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"/></>):<div className="bg-coolGray-100 z-40">Please contact the system Administrator</div>}
    </>
  );
}
