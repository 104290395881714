import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";

export default function Books() {
  //
  const [startDate, setStartDate] = useState(new Date());
  const [query, setQuery] = useState([]);
  const [books, setBooks] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const result = format(new Date(startDate), "yyyy-MM-dd");
    axios.post("/reports/date-wise", { date: result }).then((response) => {
      setPagination(response.data);
      setLinks(response.data.links);
      setBooks(response.data.data);
      console.log(response.data);
    });
  }, [startDate]);

  return (
    <div className="container relative ">
      <div className="relative flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
            tableTitle="All books"
            action={
              <div className="flex w-full">
                Month{" "}
                <DatePicker
                  className="p-0 rounded-md ml-2"
                  name="month"
                  placeholderText="Click to select month"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            }
            col_label1="Adm"
            col_label2="Student name"
            col_label3="book name"
            col_label4="isbn"
            col_label5="issued on"
            col_label6="return date"
            tableRows={
              books ? (
                books.map((book, index) => (
                  <tr key={index}>
                    <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left ">
                      {book.student.adm}
                    </th>
                    <th className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                      {book.student.name}
                    </th>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                      {book.book.name}
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                      {book.book.isbn}
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                      {book.issue_date}
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                      {book.return_date}
                    </td>
                  </tr>
                ))
              ) : (
                <p className="w-full text-center text-gray-700">no data</p>
              )
            }
          />
        </div>
        <div className="w-full p-4 flex items-center text-center">
          <div className="bg-lightBlue-900 p-2 font-light text-white w-full mb-12 px-4 rounded-lg">
            <span className="px-4 mr-20">
              {" "}
              Showing page{pagination.current_page} of {pagination.last_page}
            </span>
            {links
              ? links.map((link) => {
                  var label = link.label;
                  if (label === "&laquo; Previous") {
                    label = "Prev";
                  }
                  {
                    /* line one is a bug debeg ' */
                  }
                  if (!link.url) {
                    link.url = "";
                  }
                  var url = link.url;
                  var firstLabel = label.split(" ");
                  var lastUrl = url.slice("-7");
                  return (
                    <button
                      className="active focus  px-2 rounded-full bg-orange-500 active:border-indigo-500"
                      onClick={() => setQuery(lastUrl)}
                    >
                      {firstLabel[0]}
                    </button>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      {/*modals*/}
    </div>
  );
}
