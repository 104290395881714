import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full py-20 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-lightBlue-600 bg-no-repeat bg-full">
            <p className="w-full text-center font-bold text-3xl mt-24 text-white">
              Library Module - Kisasi Secondary School
            </p>
          </div>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Redirect from="/" to="/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
