import React, { useEffect, useState } from "react";
import axios from "axios";

// components

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {
  const [dashdata, setDashdata] = useState("");

  useEffect(() => {
    const fetchDashData = () => {
      try {
        axios
          .get("/dashboard")
          .then((response) => {
            console.log("loading dash data");
            setDashdata(response.data);

            if (response.status === 401) {
              window.location.reload();
              console.log("reloaded to authenticate");
            }
          })
          .catch((error) => {
            console.log("failed to load");
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log("needs to reloaded to authenticate");
            }
            window.location.reload();
            console.log("error", error);
          });
      } catch (error) {
        console.log("failed to load");
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.log("needs to reloaded to authenticate");
        }
        window.location.reload();
        console.log("error", error);
      }
    };
    fetchDashData();
  }, []);

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-24 pb-28 pt-4">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="ALL BOOKS"
                  statTitle={dashdata.books}
                  statArrow="right"
                  statPercent="lost_books"
                  statPercentColor="text-red-500"
                  statDescripiron={dashdata.lost_books}
                  statIconName="fas fa-book"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="BOOKS ISSUED"
                  statTitle={dashdata.issued_books}
                  statArrow="right"
                  statPercent="today's issue"
                  statPercentColor="text-emerald-500"
                  statDescripiron={dashdata.issue_today}
                  statIconName="fas fa-chart-bar"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="STUDENTS"
                  statTitle={dashdata.students}
                  statArrow=""
                  statPercent="Total staff"
                  statPercentColor="text-orange-500"
                  statDescripiron={dashdata.staff}
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="STUDENTS WITH BOOKS"
                  statTitle={dashdata.issued_books}
                  statArrow=""
                  statPercent="Staff with books"
                  statPercentColor="text-emerald-500"
                  statDescripiron={dashdata.issued_books_staff}
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
