import React, {useEffect ,useState} from "react"
import axios  from "axios";
import { toast, ToastContainer } from "react-toastify";
import  {confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";
import InputModal from "components/Modals/InputModal.jsx";

export default function Tables() {
  //
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [publishers , setPublishers] = useState([])
  const [publishersInput , setPublishersInput] = useState()
  const [publisher_id , setPublishersId] = useState()
  const [reload , setReload] = useState(false)

  const showEdit=(id)=>{
    setPublishersId(id)
    setShowModal2(true)
     }

  const handleEdit=()=>{
    axios.post("/publisher/update/"+publisher_id,
    {name:publishersInput})
    .then(response=>{
      console.log(response)
      toast.success('Done')
     }).catch((err)=>{
      toast.info('No changes were made')
      setShowModal2(false)
      reload?setReload(false):setReload(true)
     }); }

     const handleDelete =(id)=>{

      confirmAlert({
        title: 'Confirm to Delete item',
        message: 'Action is irreversible',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>{ axios.post("/publisher/delete/"+id)
            .then(response=>{
              console.log(response)
              toast.success('Done')
              reload?setReload(false):setReload(true)
             }).catch((err)=>{
              console.log(err)
              toast.error('Seems this data is in use')
             }); }
          },
          {
            label: 'No',
  
          }
        ]
      });
}

  const handleAddPublisherSubmit =()=>{
    axios.post("/publisher/create",
    {name:publishersInput})
    .then(response=>{
      console.log(response)
      setShowModal(false)
     }).catch((err)=>{
      toast.info('No changes were made')
      setShowModal2(false)
     }); }
  useEffect(() => {   
        axios.get("/publishers").then(response=>{
          setPublishers(response.data)  
          console.log('response data')
          console.log(response.data)
          console.log('state data')
          console.log(publishers)
         });  
       },
       // eslint-disable-next-line
       [reload])
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
          tableTitle="Publishers"
          action={<button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(true)}>
          Add Publisher
        </button>}
          col_label1="S.NO"
          col_label2="PUBLISHER NAME"
          col_label3="EDIT"
          col_label4="DELETE"
          tableRows={publishers.map((publisher, index) => 
            <tr   key={index}>
              <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >{publisher.id}</th>  
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{publisher.name}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
                <button class="bg-emerald-500 hover:bg-grey text-blueGray-100 font-bold py-2 px-4 rounded inline-flex items-center" onClick={()=>showEdit(publisher.id)}>
                <i className="fa fa-edit"></i>
                 <span>Edit</span>
               </button>
              </td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">
              <button class="bg-red-500 hover:bg-grey text-blueGray-100 font-bold py-2 px-4 rounded inline-flex items-center" onClick={()=>handleDelete(publisher.id)}>
                 <i className="fa fa-trash"></i>
                 <span>Delete</span>
               </button>
                </td>                              
                <td> <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <TableDropdown />
                </td></td>
             
            </tr>                 
          )}
          />
    
        </div>

      
      </div>
         {/*modals*/}     
      
     <InputModal
       modalStateProp = {showModal}
       modalTitle ="Add Publisher"
       modalClose={<>
                <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>

                    </span>
                  </button></>}

       inputs={
        <div>
         <label for="publisher-confirm" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Publisher</label>
          <input id="" type="publisher" name="publisher" placeholder="" autocomplete="new-publisher" class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner" required onChange={(e)=>setPublishersInput(e.target.value)} />
        </div>
       }           
       modalAction = {<> <button
        className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
        <button
          className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => handleAddPublisherSubmit()}              
        >
          Save
        </button></>}
       />
        <InputModal
       modalStateProp = {showModal2}
       modalTitle ="Edit Publisher"
       modalClose={<>
                <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal2(false)}
                  >
                    <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>

                    </span>
                  </button></>}

       inputs={
        <div>
         <label for="publisher-confirm" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Publisher</label>
          <input id="" type="publisher" name="publisher" placeholder="" autocomplete="new-publisher" class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded focus:bg-gray-300 focus:shadow-inner" required onChange={(e)=>setPublishersInput(e.target.value)} />
        </div>
       }           
       modalAction = {<> <button
        className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
        <button
          className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => handleEdit()}                
        >
          Save
        </button></>}
       />
         <ToastContainer position="bottom-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"/> 
    </>
    
  );
}
