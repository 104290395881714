import { React, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

// components
import InputModal from "components/Modals/InputModal.jsx";
export default function Tables() {
  const [showModal, setShowModal] = useState();
  const [showModal2, setShowModal2] = useState();
  const [showModal3, setShowModal3] = useState();
  const [showModal4, setShowModal4] = useState();
  const [showModal5, setShowModal5] = useState();
  const [showModal6, setShowModal6] = useState();
  const [promote_one_by_one, setPromoteone] = useState();
  const [demote_one_by_one, setDemoteone] = useState();
  const [createInputs, setCreateInputs] = useState({});
  const isAdmin = localStorage.getItem("isAdmin");
  const handleChange = (event) => {
    const name = event.target.name || event.target.label;
    const value = event.target.value;

    setCreateInputs((values) => ({ ...values, [name]: value }));
  };

  const handlePromote_all = (event) => {
    const adm = event.target.value;
    axios
      .post("/promote_all")
      .then((response) => {
        console.log(response);
        setShowModal3(false);
        toast.success("success");
      })
      .catch((error) => {
        console.log(error);
        toast.error("No changes were made, Failed");
      });
  };

  const handlePromote_one_by_one = (event) => {
    // console.log("promote",promote_one_by_one)

    axios
      .post("/promote_one_by_one/" + promote_one_by_one)
      .then((response) => {
        console.log(response);
        setShowModal4(false);
        toast.success("success");
      })
      .catch((error) => {
        console.log(error);
        toast.error("No changes were made, Failed");
      });
  };
  const handleDemote_all = (event) => {
    axios
      .post("/demote_all")
      .then((response) => {
        console.log(response);
        setShowModal5(false);
        toast.success("success");
      })
      .catch((error) => {
        console.log(error);
        toast.error("No changes were made, Failed");
      });
  };
  const handleDemote_one_by_one = (event) => {
    var adm = event.target.value;
    axios
      .post("/demote_one_by_one/" + demote_one_by_one)
      .then((response) => {
        console.log(response);
        setShowModal6(false);
        toast.success("success");
      })
      .catch((error) => {
        console.log(error);
        toast.error("No changes were made, Failed");
      });
  };
  const handleSubmit = () => {
    console.log(createInputs);
    if (!createInputs.return_days || !createInputs.fine) {
      toast.error("Please fill all the fields");
      return 0;
    }
    axios
      .post("/settings", createInputs)
      .then((response) => {
        console.log(response);
        setShowModal(false);
        toast.success("success");
      })
      .catch((error) => {
        console.log(error);
        toast.error("No changes were made, Failed");
      });
  };
  return (
    <>
      <div className="w-fill p-8 text-center mt-24 text-xl font-bold">
        Please proceed with caution, these actions may cause data breach if
        mis-hadled!
      </div>
      <div className="w-fill p-8 text-center mt-4">
        <button
          className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Book Settings
        </button>
        <button
          className="bg-red-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            if (!isAdmin) {
              toast.error("Action Limited System Administrator");
              return 0;
            }
            setShowModal3(true);
          }}
        >
          Promote All Students
        </button>
        <button
          className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            if (!isAdmin) {
              toast.error("Action Limited System Administrator");
              return 0;
            }
            setShowModal4(true);
          }}
        >
          Promote one by one
        </button>
        <button
          className="bg-red-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            if (!isAdmin) {
              toast.error("Action Limited System Administrator");
              return 0;
            }
            setShowModal5(true);
          }}
        >
          Demote All Students
        </button>
        <button
          className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            if (!isAdmin) {
              toast.error("Action Limited System Administrator");
              return 0;
            }
            setShowModal6(true);
          }}
        >
          Demote one by one
        </button>
      </div>
      <InputModal
        modalStateProp={showModal}
        modalTitle={
          <>
            <i class="fa fa-cog"></i>Settings
          </>
        }
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="return_days"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Book Return Days
            </label>
            <input
              id="return_days"
              type="return_days"
              name="return_days"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded-full focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.return_days || ""}
              onChange={handleChange}
            />
            <label
              for="fine"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Fine (Ksh) per days if book not returned
            </label>
            <input
              id="fine"
              type="name"
              name="fine"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded-full focus:bg-gray-300 focus:shadow-inner"
              required
              value={createInputs.fine || ""}
              onChange={handleChange}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal3}
        modalTitle={
          <>
            <i class="fa fa-cog"></i>Promotion Settings - All students
          </>
        }
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal3(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={<div>Please confirm this action</div>}
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal3(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handlePromote_all}
            >
              promote
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal4}
        modalTitle={
          <>
            <i class="fa fa-cog"></i>Promotion Settings -one by one
          </>
        }
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal4(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="name"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Admission Number
            </label>
            <input
              id="fine"
              type="name"
              name="fine"
              placeholder=""
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded-full focus:bg-gray-300 focus:shadow-inner"
              onChange={(e) => setPromoteone(e.target.value)}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal4(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handlePromote_one_by_one}
            >
              Save
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal5}
        modalTitle={
          <>
            <i class="fa fa-cog"></i>Promotion Settings - Demote All
          </>
        }
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal5(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={<div>Please confirm this action</div>}
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal5(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleDemote_all}
            >
              Demote
            </button>
          </>
        }
      />

      <InputModal
        modalStateProp={showModal6}
        modalTitle={
          <>
            <i class="fa fa-cog"></i>Promotion Settings -Demote one by one
          </>
        }
        modalClose={
          <>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal6(false)}
            >
              <span className="text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </>
        }
        inputs={
          <div>
            <label
              for="fine"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Admission Number
            </label>
            <input
              id="fine"
              type="name"
              name="fine"
              placeholder=""
              autocomplete="bookname"
              class="block w-full px-2 border-2 bg-gray-200 mt-1 text-gray-700  appearance-none focus:outline-none rounded-full focus:bg-gray-300 focus:shadow-inner"
              onChange={(e) => setDemoteone(e.target.value)}
            />
          </div>
        }
        modalAction={
          <>
            {" "}
            <button
              className="bg-red-500 text-white active:bg-red-600  font-bold uppercase text-sm px-6 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal6(false)}
            >
              Cancel
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600  font-bold uppercase text-sm px-8 py-1 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleDemote_one_by_one}
            >
              Save
            </button>
          </>
        }
      />

      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
