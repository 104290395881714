import ReactDOM from 'react-dom';
const modal_root =document.getElementById('modal-root');
export default function Modal(props) {
    const showModal = props.modalStateProp
    console.log(props.modalStateProp)
  return ReactDOM.createPortal(
    <>
{showModal? (
<>
   <div
  className="bg-black p-8  justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" style={{inset:'0',backgroundColor:'rgba(0,0,0,0.3)'}}
   
>
  <div className="relative w-auto my-6 mx-auto max-w-sm">
    {/*content*/}
    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      {/*header*/}
      <div className="flex items-start justify-between p-2 px-4 border-b border-solid border-blueGray-200 rounded-t">
        <h4 className="text-lg font-semibold">
          {props.modalTitle}
        </h4>
        {props.modalClose}
      </div>
      {/*body*/}
      <div className="relative px-4 py-2 flex-auto">
        <p className="mx-4 text-blueGray-500 text-lg leading-relaxed">
          {props.children}
        </p>
      </div>
      {/*footer*/}
    </div>
  </div>
</div>
</>
) : null}
   </>,modal_root
  );
}




