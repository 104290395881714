import React, {useEffect ,useState} from "react"
import axios  from "axios";
import { toast, ToastContainer } from "react-toastify";
import  {confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";
import CardTable from "components/Cards/CardTable.js";
import InputModal from "components/Modals/InputModal.jsx";


export default function Tables() {
  //
  const [name , setName] = useState([])
  const [reload , setReload] = useState(false)

   
    

  useEffect(() => {   
        axios.get("/count_name").then(response=>{
          setName(response.data)  
            });  
       },
       // eslint-disable-next-line
       [reload])
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable
          tableTitle="book title count"   
          col_label1="title"
          col_label2="count"
          tableRows={name.map((name, index) => 
            <tr   key={index}>
              <th className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center px-4 text-left " >{name.id}</th>  
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center">{name.name}</td>
            </tr>                 
          )}
          />
    
        </div>

      </div>
         {/*modals*/}     
    
         <ToastContainer position="bottom-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"/> 
    </>
  );
}
